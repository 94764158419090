import { Pathes } from '@/constants';
import { MainLayout } from '@/layouts';
import { IRouterParams } from '@/router/types';
import {
  CreateUserPage,
  EditUserPage,
  MainUserPage,
  SiteSettingsPage,
  MainLogsPage,
  ViewLogPage,
  MainFeedbackPage,
  EditFeedbackPage,
  MainPostPage,
  CreatePostPage,
  EditPostPage,
  MainMetadataPage,
  EditMetadataPage,
  CreateMetadataPage,
  MainHistoryPointPage,
  CreateHistoryPointPage,
  EditHistoryPointPage,
} from '@/views';
import { EditVacancyPage, VacanciesPage } from '@/views/vacancies';
import { CreateVacancyPage } from '@/views/vacancies/CreateVacancyPage';

const getPathForViewingById = (path: string) => `${path}/view/:id`;
const getPathForEditingById = (path: string) => `${path}/edit/:id`;
const getPathForCreating = (path: string) => `${path}/create`;

const routes: IRouterParams[] = [
  {
    component: MainUserPage,
    layout: MainLayout,
    path: Pathes.Users,
    title: 'Users',
    breadCrumds: [{ title: 'Users', path: Pathes.Users }],
  },
  {
    component: EditUserPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Users),
    title: 'Edit User',
    breadCrumds: [
      { title: 'Users', path: Pathes.Users },
      { title: 'Edit User' },
    ],
  },
  {
    component: CreateUserPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Users),
    title: 'Create User',
    breadCrumds: [
      { title: 'Users', path: Pathes.Users },
      { title: 'Create User' },
    ],
  },
  {
    component: SiteSettingsPage,
    layout: MainLayout,
    path: Pathes.Settings,
    title: 'Site settings',
    breadCrumds: [{ title: 'Site settings', path: Pathes.Settings }],
  },
  {
    component: MainFeedbackPage,
    layout: MainLayout,
    path: Pathes.Feedbacks,
    title: 'Feedbacks',
    breadCrumds: [{ title: 'Feedbacks', path: Pathes.Feedbacks }],
  },
  {
    component: EditFeedbackPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Feedbacks),
    title: 'Edit Feedback',
    breadCrumds: [
      { title: 'Feedbacks', path: Pathes.Feedbacks },
      { title: 'Edit Feedback' },
    ],
  },
  {
    component: MainPostPage,
    layout: MainLayout,
    path: Pathes.Posts,
    title: 'Posts',
    breadCrumds: [{ title: 'Posts', path: Pathes.Posts }],
  },

  {
    component: CreatePostPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Posts),
    title: 'Create Post',
    breadCrumds: [
      { title: 'Posts', path: Pathes.Posts },
      { title: 'Create Post' },
    ],
  },
  {
    component: EditPostPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Posts),
    title: 'Edit Post',
    breadCrumds: [
      { title: 'Posts', path: Pathes.Posts },
      { title: 'Edit Post' },
    ],
  },
  {
    component: MainHistoryPointPage,
    layout: MainLayout,
    path: Pathes.HistoryPoints,
    title: 'History Points',
    breadCrumds: [{ title: 'History Points', path: Pathes.HistoryPoints }],
  },
  {
    component: CreateHistoryPointPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.HistoryPoints),
    title: 'Create History Point',
    breadCrumds: [
      { title: 'History Points', path: Pathes.HistoryPoints },
      { title: 'Create History Point' },
    ],
  },
  {
    component: EditHistoryPointPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.HistoryPoints),
    title: 'Edit History Point',
    breadCrumds: [
      { title: 'History Points', path: Pathes.HistoryPoints },
      { title: 'Edit History Point' },
    ],
  },
  {
    component: MainLogsPage,
    layout: MainLayout,
    path: Pathes.Logs,
    title: 'Logs',
    breadCrumds: [{ title: 'Logs', path: Pathes.Logs }],
  },
  {
    component: ViewLogPage,
    layout: MainLayout,
    path: getPathForViewingById(Pathes.Logs),
    title: 'View log',
    breadCrumds: [{ title: 'Logs', path: Pathes.Logs }, { title: 'View log' }],
  },
  {
    component: MainMetadataPage,
    layout: MainLayout,
    path: Pathes.Metadata,
    title: 'Metadata',
    breadCrumds: [{ title: 'Metadata', path: Pathes.Metadata }],
  },
  {
    component: CreateMetadataPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Metadata),
    title: 'Create Metadata',
    breadCrumds: [
      { title: 'Metadata', path: Pathes.Metadata },
      { title: 'Create Metadata' },
    ],
  },
  {
    component: EditMetadataPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Metadata),
    title: 'Edit Metadata',
    breadCrumds: [
      { title: 'Metadata', path: Pathes.Metadata },
      { title: 'Edit Metadata' },
    ],
  },

  {
    component: VacanciesPage,
    layout: MainLayout,
    path: Pathes.Vacancies,
    title: 'Positions',
    breadCrumds: [{ title: 'Positions', path: Pathes.Vacancies }],
  },
  {
    component: CreateVacancyPage,
    layout: MainLayout,
    path: getPathForCreating(Pathes.Vacancies),
    title: 'Create Position',
    breadCrumds: [
      { title: 'Positions', path: Pathes.Vacancies },
      { title: 'Create Position' },
    ],
  },
  {
    component: EditVacancyPage,
    layout: MainLayout,
    path: getPathForEditingById(Pathes.Vacancies),
    title: 'Edit Position',
    breadCrumds: [
      { title: 'Positions', path: Pathes.Vacancies },
      { title: 'Edit Position' },
    ],
  },
];

export default routes;
