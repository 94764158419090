import { useNavigate } from 'react-router-dom';

import { useGetPostsQuery } from '@/apis/posts';
import { SortingOrderEnum } from '@/enums/sorting/sortingOrder';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useMainPostPage = () => {
  const navigate = useNavigate();
  const [filters, onFiltersChange, onSortingChange, sortBy, isOrderByAsc] =
    useTableFilters(filteringFields);
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: posts, isLoading } = useGetPostsQuery({
    limit,
    page: page + 1,
    sortBy,
    ...(isOrderByAsc !== undefined && {
      order: isOrderByAsc ? SortingOrderEnum.ASC : SortingOrderEnum.DESC,
    }),
    ...filters,
  });

  const count = (posts?.meta?.totalPages ?? 1) * limit;

  const noData = !posts?.items.length;

  const goToCreatePostPage = () => {
    navigate('create');
  };

  return {
    posts,
    count,
    noData,
    isLoading,
    onFiltersChange,
    goToCreatePostPage,
    onSortingChange,
    sortBy,
    isOrderByAsc,
    ...paginationParams,
  };
};
