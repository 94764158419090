
export const styles = {
  container: {
    height: '100%',
  },
  icon: {
    color: 'grey.A200',
  },
  listItem: {
    color: 'white',
  },
  title: {
    textDecoration: 'none',
    color: 'white',
  },
} as const;
