export enum Pathes {
  Main = '/',
  SignIn = '/sign-in',
  Users = '/users',
  Settings = '/settings',
  Logs = '/logs',
  Feedbacks = '/feedbacks',
  Posts = '/posts',
  Metadata = '/meta-data',
  HistoryPoints = '/history-points',
  Vacancies = '/vacancies',
}
