export const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column-reverse',
    },
  },
  btn: {
    width: 120,
  },
  icon: {
    width: {
      md: '50%',
      xs: '100%',
    },
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteBtn: {
    mt: 3,
    ml: 1,
    width: 20,
    height: 40,
    px: 0,
  },
} as const;
