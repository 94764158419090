import { useMemo } from 'react';

import {
  useCreateWebsiteSettingsMutation,
  useGetWebsiteSettingsQuery,
  useUpdateWebsiteSettingsMutation,
} from '@/apis/website-settings';
import { useToastMessageRequest } from '@/hooks';

import type { IWebsiteSettingsFormParams } from '@/types';

export const useSiteSettingsForm = () => {
  const { data: settings } = useGetWebsiteSettingsQuery();
  const id = settings?.id;
  const [
    updateSettings,
    { isLoading: isUpdateLoading, ...requestUpdateParams },
  ] = useUpdateWebsiteSettingsMutation();
  const [
    createSettings,
    { isLoading: isCreateLoading, ...requestCreateParams },
  ] = useCreateWebsiteSettingsMutation();

  useToastMessageRequest(requestUpdateParams);
  useToastMessageRequest(requestCreateParams);

  const initialValues = useMemo(
    () => ({
      id: settings?.id ?? '',
      adminEmail: settings?.adminEmail ?? '',
      recruiterEmail: settings?.recruiterEmail ?? '',
    }),
    [settings]
  );
  const onSubmit = (params: IWebsiteSettingsFormParams) => {
    settings
      ? updateSettings({ id, ...params })
      : createSettings({ id, ...params });
  };
  return { initialValues, isUpdateLoading, isCreateLoading, onSubmit };
};
