import { object } from 'yup';

import {
  text,
  email,
  initialPassword,
  editPassword,
  nickname,
  firstName,
} from '@/constants';

export const userCreateFormSchema = object({
  firstName,
  role: text,
  email,
  password: initialPassword,
  userName: nickname,
}).required();

export const userEditFormSchema = object({
  firstName,
  role: text,
  email,
  password: editPassword,
  userName: nickname,
}).required();
