import { createSlice } from '@reduxjs/toolkit';

import type { IMetadatasState } from './types';

const initialState: IMetadatasState = {
  isDeleteMetadataModalOpen: false,
  selectedMetadataForModal: null,
};

export const metadatasSlice = createSlice({
  name: 'metadatas',
  initialState,
  reducers: {
    openDeleteMetadataModal(state: IMetadatasState, { payload: metadata }) {
      state.isDeleteMetadataModalOpen = true;
      state.selectedMetadataForModal = metadata;
    },
    closeDeleteMetadataModal(state: IMetadatasState) {
      state.isDeleteMetadataModalOpen = false;
      state.selectedMetadataForModal = null;
    },
  },
});

export const { openDeleteMetadataModal, closeDeleteMetadataModal } =
  metadatasSlice.actions;

export default metadatasSlice.reducer;
