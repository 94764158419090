import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteLogsMutation } from '@/apis/logs';
import { useToastMessageRequest } from '@/hooks';
import { logsSelectors } from '@/redux/logs';
import { closeDeleteAllLogsModal } from '@/redux/logs/slice';

export const useDeleteAllLogsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(logsSelectors.isDeleteAllLogsModalOpenSelector);

  const [deleteAllLogs, { isSuccess, ...requestParams }] = useDeleteLogsMutation();

  const closeModal = () => {
    dispatch(closeDeleteAllLogsModal());
  }

  const confirmDeleting = () => {
    deleteAllLogs();
  }

  useToastMessageRequest({ isSuccess, ...requestParams });

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, confirmDeleting, closeModal }
}
