import { Delete, Edit } from '@mui/icons-material';
import { TableCell, TableRow, IconButton, colors } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTableItem } from '@/hooks';
import { openDeleteUserModal } from '@/redux/users/slice';

import type { IUserResponse } from '@/types';

const UserTableItem: React.FC<IUserResponse> = (user) => {
  const { reqId, id, firstName, lastName, email, role, createdAt, status } =
    user;

  const { onEdit, onDelete } = useTableItem(openDeleteUserModal);

  const createdDate = format(new Date(createdAt), 'dd.MM.yyyy');

  return (
    <TableRow>
      <TableCell component="th" scope="row" width={100}>
        <Link
          to={`edit/${id}`}
          style={{ textDecoration: 'none', color: colors.blue[600] }}
        >
          {reqId}
        </Link>
      </TableCell>
      <TableCell align="left">{firstName}</TableCell>
      <TableCell align="left">{lastName}</TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="left" width={100}>
        {role}
      </TableCell>
      <TableCell align="left" width={150}>
        {createdDate}
      </TableCell>
      <TableCell>
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(user)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default UserTableItem;
