import { IVacancyResponse } from '@/types';

export const categories = ['Engineering', 'Management'];

export const defaultInitialValues: Omit<
  IVacancyResponse,
  'id' | 'createdAt' | 'updatedAt' | 'reqId'
> = {
  title: '',
  description: '',
  content: [
    {
      type: 'HTML',
      data: {
        content: '',
      },
    },
  ],
  metaTitle: '',
  metaH1: '',
  metaDescription: '',
  metaKeywords: '',
  metaSubTitle: '',
  slug: '',
  noIndex: false,
  category: categories[0],
};
