import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { ICreateAttachmentsResponse } from '@/types';

const { api } = envUtil.getEnv();

export const attachmentsApi = createApi({
  reducerPath: 'attachmentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Attachments', 'Attachment'],
  endpoints: (builder) => ({
    uploadAttachment: builder.mutation<ICreateAttachmentsResponse, File[]>({
      query: (files) => {
        const formData = new FormData();
        files.forEach((file: File) => {
          formData.append('attachment[]', file);
        });
        return {
          url: `${ApiRoutes.Attachments}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const { useUploadAttachmentMutation } = attachmentsApi;
