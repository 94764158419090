import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {useDeleteHistoryPointMutation} from "@/apis/history";
import { useToastMessageRequest } from '@/hooks';
import { historySelectors } from '@/redux/historyPoints';
import {closeDeleteHistoryModal} from "@/redux/historyPoints/slice";

export const useDeleteHistoryPointModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(historySelectors.isDeleteHistoryModalOpenSelector);
  const historyPoint = useSelector(historySelectors.selectedHistoryForModalSelector);

  const [deleteHistoryPoint, { isSuccess, ...requestParams }] = useDeleteHistoryPointMutation()

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteHistoryModal());
  };

  const confirmDeleting = () => {
    deleteHistoryPoint(historyPoint?.id ?? '');
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, historyPoint, closeModal, confirmDeleting };
};
