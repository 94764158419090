import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEditFeedbackMutation,
  useGetAttachmentFileMutation,
  useGetFeedbackByIdQuery,
} from '@/apis/feedbacks';
import { useToastMessageRequest } from '@/hooks';
import { processDownloadingFileByUrl } from '@/utils/files';

export const useEditFeedbackPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: feedback } = useGetFeedbackByIdQuery(id);
  const [
    editFeedback,
    { isLoading: isEditFeedbackLoading, ...editFeedbackRequestParams },
  ] = useEditFeedbackMutation();

  const [getAttachmentFileRequest, { data: attachmentFileUrl }] =
    useGetAttachmentFileMutation();

  const downloadAttachmentFile = () =>
    feedback?.file ? getAttachmentFileRequest(feedback.file) : undefined;

  useEffect(() => {
    if (!attachmentFileUrl) return;

    processDownloadingFileByUrl({
      url: attachmentFileUrl,
      fileName: feedback?.file ?? 'attachment',
    });
  }, [attachmentFileUrl]);

  useToastMessageRequest(editFeedbackRequestParams);

  const initialFeedbackValues = useMemo(
    () => ({
      id: feedback?.id ?? '',
      reqId: feedback?.reqId ?? '',
      type: feedback?.type ?? '',
      status: feedback?.status ?? '',
      name: feedback?.name ?? '',
      email: feedback?.email ?? '',
      company: feedback?.company ?? '',
      file: feedback?.file ?? '',
      createdAt: feedback?.createdAt ?? '',
      updatedAt: feedback?.updatedAt ?? '',
      message: feedback?.message ?? '',
      position: feedback?.position ?? '',
    }),
    [feedback]
  );

  const onFeedbackSubmit = useCallback(
    ({ ...params }: any) => {
      editFeedback({
        id,
        ...params,
      });
    },
    [id]
  );

  return {
    initialFeedbackValues,
    isEditFeedbackLoading,
    onFeedbackSubmit,
    downloadAttachmentFile,
  };
};
