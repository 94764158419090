import {
  Button,
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import { Field, FieldArray, Form, Formik } from 'formik';
import React from 'react';

import { PostContentEditor, SelectInput, TextInput } from '@/components';
import { SwitchInput } from '@/components/inputs/SwitchInput';
import { IVacancyResponse } from '@/types';
import { capitalize } from '@/utils';

import { categories, defaultInitialValues } from './constants';
import { postFormSchema } from './schemas';
import { styles } from './styles';


type VacancyFormValues = IVacancyResponse;

const VacancyForm: React.FC<any> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  return (
    <Formik<VacancyFormValues>
      enableReinitialize
      validationSchema={postFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty, values, handleChange }) => {
        return (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <SwitchInput
                  placeholder="No index (hide from users)"
                  onChange={handleChange}
                  name="noIndex"
                  checked={values?.['noIndex']}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="slug"
                  autoComplete="slug"
                  fullWidth
                  component={TextInput}
                  placeholder="Slug"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="title"
                  autoComplete="title"
                  fullWidth
                  component={TextInput}
                  placeholder="Title"
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="category"
                  placeholder="Category"
                  component={SelectInput}
                  size="small"
                >
                  {categories.map((category) => (
                    <MenuItem value={category} key={category}>
                      <Typography>{capitalize(category)}</Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="description"
                  autoComplete="description"
                  fullWidth
                  component={TextInput}
                  placeholder="Description"
                />
              </Grid>
            </Grid>
            <InputLabel>Content</InputLabel>
            <FieldArray
              name="content"
              render={(arrayHelpers) => (
                <PostContentEditor
                  arrayHelpers={arrayHelpers}
                  content={values.content}
                />
              )}
            />
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="metaTitle"
                  autoComplete="metaTitle"
                  fullWidth
                  component={TextInput}
                  placeholder="Meta title"
                />
              </Grid>
              <Grid item xs>
                <Field
                  type="text"
                  name="metaH1"
                  autoComplete="metaH1"
                  fullWidth
                  component={TextInput}
                  placeholder="Meta H1"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="metaDescription"
                  autoComplete="metaDescription"
                  fullWidth
                  component={TextInput}
                  placeholder="Meta description"
                />
              </Grid>
              <Grid item xs>
                <Field
                  type="text"
                  name="metaKeywords"
                  autoComplete="metaKeywords"
                  fullWidth
                  component={TextInput}
                  placeholder="Meta keywords"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="metaSubTitle"
                  autoComplete="metaSubTitle"
                  fullWidth
                  component={TextInput}
                  placeholder="Sub title for cover image"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !dirty}
                sx={styles.btn}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VacancyForm;
