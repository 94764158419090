import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditUserMutation, useGetUserByIdQuery } from '@/apis/users';
import { useToastMessageRequest } from '@/hooks';

import type { IUserFormParams } from '@/types';

export const useEditUserPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: user } = useGetUserByIdQuery(id);
  const [editUser, { isLoading: isEditUserLoading, ...editUserRequestParams }] =
    useEditUserMutation();

  useToastMessageRequest(editUserRequestParams);

  const initialUserValues = useMemo(
    () => ({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      userName: user?.userName ?? '',
      email: user?.email ?? '',
      password: user?.password ?? '',
      role: user?.role ?? '',
    }),
    [user]
  );

  const onUserSubmit = useCallback(
    ({ ...params }: IUserFormParams) => {
      editUser({
        id,
        ...params,
      });
    },
    [id]
  );

  return {
    initialUserValues,
    isEditUserLoading,
    onUserSubmit,
  };
};
