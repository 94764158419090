import React from 'react';

import { DeleteConfirmationDialog } from '@/components';
import {useDeleteHistoryPointModal} from "@/components/modals/DeleteHistoryPointModal/useDeleteHistoryPointModal";

const DeleteHistoryPointModal: React.FC = () => {
  const { isOpen, historyPoint, closeModal, confirmDeleting } = useDeleteHistoryPointModal();
  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete history point #${historyPoint?.reqId ?? ''}?`}
    />
  );
};

export default DeleteHistoryPointModal;
