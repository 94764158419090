import React from 'react';

import { MetadataForm } from '@/components/forms/MetadataForm/MetadataForm';

import { useCreateMetadataPage } from './useCretaeMetadataPage';

export const CreateMetadataPage: React.FC = () => {
  const props = useCreateMetadataPage();
  return <MetadataForm {...props} />;
};
