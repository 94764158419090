import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  TableCell,
  TableRow,
  colors,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTableItem } from '@/hooks';
import {openDeleteHistoryModal} from "@/redux/historyPoints/slice";

import { styles } from './styles';

//todo
const HistoryPointTableItem: React.FC<any> = (historyPoint) => {
  const { id, reqId, date, image, type, title, createdAt, updatedAt } = historyPoint;
  const imageUrl = image?.url;
  const { onEdit, onDelete } = useTableItem(openDeleteHistoryModal);

  const createdDate = format(new Date(createdAt), 'dd.MM.yyyy HH:MM:SS');
  const updateDate = format(new Date(updatedAt), 'dd.MM.yyyy HH:MM:SS');
  const dateNormalizes = format(new Date(date), 'dd.MM.yyyy');
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <RouterLink
          to={`edit/${id}`}
          style={{ textDecoration: 'none', color: colors.blue[600] }}
        >
          {reqId}
        </RouterLink>
      </TableCell>
      <TableCell component="th" scope="row">
        {dateNormalizes}
      </TableCell>
      <TableCell component="th" scope="row">
        {type}
      </TableCell>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell component="th" scope="row">
        <Link href={imageUrl} target="_blank" sx={styles.title}>
          <Box component="img" style={{ height: 30 }} src={imageUrl} />
        </Link>
      </TableCell>
      <TableCell align="left">{createdDate}</TableCell>
      <TableCell align="left">{updateDate}</TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(historyPoint)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default HistoryPointTableItem;
