import React from 'react';

import { DeleteConfirmationDialog } from '@/components';

import { useDeleteMetadataModal } from './useDeleteMetadataModal';

const DeleteMetadataModal: React.FC = () => {
  const { isOpen, metadata, closeModal, confirmDeleting } =
    useDeleteMetadataModal();

  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete metadata record #${
        metadata?.reqId ?? ''
      }?`}
    />
  );
};

export default DeleteMetadataModal;
