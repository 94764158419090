import { createSlice } from '@reduxjs/toolkit';

import {IHistoryState} from "@/redux/historyPoints/types";

const initialState: IHistoryState = {
  isDeleteHistoryModalOpen: false,
  selectedHistoryForModal: null,
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    //todo
    openDeleteHistoryModal(state: any, { payload: historyPoint }) {
      console.log('historyPoint', historyPoint)
      state.isDeleteHistoryModalOpen = true;
      state.selectedHistoryForModal = historyPoint;
    },
    //todo
    closeDeleteHistoryModal(state: any) {
      state.isDeleteHistoryModalOpen = false;
      state.selectedHistoryForModal = null;
    },
  },
});

export const { closeDeleteHistoryModal, openDeleteHistoryModal } = historySlice.actions;

export default historySlice.reducer;
