import { FormControl, Switch, Typography } from '@mui/material';
import React from 'react';

import type { ISwitchInputProps } from './types';

function SwitchInput<
  V = string,
  FormValues extends Record<string, unknown> = Record<string, unknown>
>({ ...props }: ISwitchInputProps<V, FormValues>) {
  return (
    <FormControl fullWidth>
      {props.placeholder && (
        <Typography variant="h6" mb={1}>
          {props.placeholder}
        </Typography>
      )}
      <Switch {...props} size="small" />
    </FormControl>
  );
}

export default SwitchInput;
