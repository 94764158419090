import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { CustomTable } from '@/components';
import { DeleteMetadataModal } from '@/components/modals/DeleteMetadataModal';
import { MetadataTableItem } from '@/components/table-items/MetadataTableItem';

import { filteringFields, headCells } from './constants';
import { useMainMetadataPage } from './useMainMetadataPage';

export const MainMetadataPage = () => {
  const { metadatas, goToCreateMetadataPage, ...tableProps } =
    useMainMetadataPage();

  return (
    <>
      <Button variant="contained" onClick={goToCreateMetadataPage}>
        <Add />
        <Typography ml={2} fontWeight="bold">
          Create new
        </Typography>
      </Button>

      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeleteMetadataModal}
        {...tableProps}
      >
        {metadatas?.items.map((metadata) => (
          <MetadataTableItem {...metadata} key={metadata.id} />
        ))}
      </CustomTable>
    </>
  );
};
