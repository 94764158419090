import { createSlice } from '@reduxjs/toolkit';

import type { IVacanciesState } from './types';

const initialState: IVacanciesState = {
  isDeleteVacancyModalOpen: false,
  selectedVacancyForModal: null,
};

export const vacanciesSlice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    openDeleteVacancyModal(state: IVacanciesState, { payload: vacancy }) {
      state.isDeleteVacancyModalOpen = true;
      state.selectedVacancyForModal = vacancy;
    },
    closeDeleteVacancyModal(state: IVacanciesState) {
      state.isDeleteVacancyModalOpen = false;
      state.selectedVacancyForModal = null;
    },
  },
});

export const { openDeleteVacancyModal, closeDeleteVacancyModal } =
  vacanciesSlice.actions;

export default vacanciesSlice.reducer;
