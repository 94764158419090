import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, InputAdornment, IconButton, Typography, FormControl } from '@mui/material';
import React, { useCallback, useState } from 'react';

import type { IPasswordInputProps } from './types';

function PasswordInput<
  V = string,
  FormValues extends Record<string, unknown> = Record<string, unknown>,
>({ field, form: { touched, errors }, ...props }: IPasswordInputProps<V, FormValues>) {
  const [isPasswordShown, setPasswordShown] = useState(false);

  const onToggleShowPassword = useCallback(() => {
    setPasswordShown((val) => !val);
  }, []);

  const hasError = Boolean(touched[field.name] && errors[field.name]);

  return (
    <FormControl fullWidth>
      <Typography variant="h6" mb={1}>
        {props.placeholder}
      </Typography>
      <TextField
        {...field}
        {...props}
        helperText={(hasError ? errors[field.name] : ' ') as string}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Toggle password visibility" onClick={onToggleShowPassword}>
                {isPasswordShown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          ...props.InputProps,
        }}
        type={isPasswordShown ? 'text' : 'password'}
        error={hasError}
        placeholder="Password"
        size="small"
      />
    </FormControl>
  );
}

export default PasswordInput;
