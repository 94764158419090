import { Breadcrumbs, Box, Typography } from '@mui/material';
import React, { FC } from 'react';

import { CustomLink } from '@/components';

import type { IBreadCrumb } from '@/types';

interface IBreadcrumbsComponentProps {
  items: IBreadCrumb[];
}

const BreadcrumbsComponent: FC<IBreadcrumbsComponentProps> = ({ items }) => (
  <Breadcrumbs aria-label="breadcrumb">
    <CustomLink to="/">Main</CustomLink>
    {items.map(({ title, path }, idx) => (
      <Box key={idx}>
        {idx === items.length - 1 || !path ? (
          <Typography color="text.primary" key={title}>
            {title}
          </Typography>
        ) : (
          <CustomLink to={path}>{title}</CustomLink>
        )}
      </Box>
    ))}
  </Breadcrumbs>
);

export default BreadcrumbsComponent;
