import {
  Person,
  Settings,
  // Description,
  FeedbackOutlined,
  LocalPostOfficeOutlined,
  TipsAndUpdatesOutlined,
  Timelapse,
  PersonSearchOutlined,
} from '@mui/icons-material';

import { Pathes } from './pathes';

export const navigationList = [
  {
    name: 'Users',
    path: Pathes.Users,
    icon: Person,
  },
  {
    name: 'Site settings',
    path: Pathes.Settings,
    icon: Settings,
  },
  {
    name: 'Feedbacks',
    path: Pathes.Feedbacks,
    icon: FeedbackOutlined,
  },

  {
    name: 'Posts',
    path: Pathes.Posts,
    icon: LocalPostOfficeOutlined,
  },
  // {
  //   name: 'Logs',
  //   path: Pathes.Logs,
  //   icon: Description,
  // },

  {
    name: 'Metadata',
    path: Pathes.Metadata,
    icon: TipsAndUpdatesOutlined,
  },
  {
    name: 'History Points',
    path: Pathes.HistoryPoints,
    icon: Timelapse,
  },
  {
    name: 'Positions',
    path: Pathes.Vacancies,
    icon: PersonSearchOutlined,
  },
];
