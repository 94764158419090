import React from 'react';

import { DeleteConfirmationDialog } from '@/components';
import { useDeleteLogModal } from '@/components/modals/DeleteLogModal/useDeleteLogModal';

export const DeleteLogModal: React.FC = () => {
  const { isOpen, log, closeModal, confirmDeleting } = useDeleteLogModal();

  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete log #${log?.id}?`}
    />
  );
};
