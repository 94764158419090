import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, colors } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTableItem } from '@/hooks';
import { openDeleteVacancyModal } from '@/redux/vacancies/slice';
import { truncateString } from '@/utils';

import type { IVacancyResponse } from '@/types';

const VacancyTableItem: React.FC<IVacancyResponse> = (vacancy) => {
  const { id, reqId, title, slug, description, category, createdAt } = vacancy;
  const { onEdit, onDelete } = useTableItem(openDeleteVacancyModal);
  const createdDate = format(new Date(createdAt), 'dd.MM.yyyy HH:MM:SS');

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <RouterLink
          to={`edit/${id}`}
          style={{ textDecoration: 'none', color: colors.blue[600] }}
        >
          {reqId}
        </RouterLink>
      </TableCell>
      <TableCell component="th" scope="row">
        {truncateString(title)}
      </TableCell>
      <TableCell component="th" scope="row">
        {slug}
      </TableCell>
      <TableCell component="th" scope="row">
        {truncateString(description)}
      </TableCell>
      <TableCell component="th" scope="row">
        {category}
      </TableCell>
      <TableCell align="left">{createdDate}</TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(vacancy)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default VacancyTableItem;
