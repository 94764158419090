import { FormControl, TextField, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import type { ITextInputProps } from './types';

function TextInput<
  V = string,
  FormValues extends Record<string, unknown> = Record<string, unknown>
>({
  field,
  form: { touched, errors },
  error,
  helperText,
  hideEmptyHelperText = false,
  ...props
}: ITextInputProps<V, FormValues>) {
  const hasError = error ?? Boolean(touched[field.name] && errors[field.name]);
  return (
    <FormControl fullWidth>
      {props.placeholder && (
        <Typography variant="h6" mb={1}>
          {props.placeholder}
        </Typography>
      )}
      <TextField
        {...field}
        {...props}
        size="small"
        error={hasError}
        helperText={
          (hasError
            ? helperText ?? errors[field.name]
            : hideEmptyHelperText
            ? null
            : ' ') as ReactNode
        }
      />
    </FormControl>
  );
}

export default TextInput;
