import { useGetFeedbacksQuery } from '@/apis/feedbacks';
import { SortingOrderEnum } from '@/enums/sorting/sortingOrder';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useMainFeedbackPage = () => {
  const [filters, onFiltersChange, onSortingChange, sortBy, isOrderByAsc] =
    useTableFilters(filteringFields);
  console.log({ filters });
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: feedbacks, isLoading } = useGetFeedbacksQuery({
    limit,
    page: page + 1,
    sortBy,
    ...(isOrderByAsc !== undefined && {
      order: isOrderByAsc ? SortingOrderEnum.ASC : SortingOrderEnum.DESC,
    }),
    ...filters,
  });

  const count = (feedbacks?.meta?.totalPages ?? 1) * limit;

  const noData = !feedbacks?.items.length;

  return {
    feedbacks,
    count,
    noData,
    isLoading,
    onFiltersChange,
    onSortingChange,
    sortBy,
    isOrderByAsc,
    ...paginationParams,
  };
};
