import React from 'react';

import { DeleteConfirmationDialog } from '@/components';
import { useDeletePostModal } from '@/components/modals/DeletePostModal/useDeletePostModal';

const DeletePostModal: React.FC = () => {
  const { isOpen, post, closeModal, confirmDeleting } = useDeletePostModal();

  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete post #${post?.reqId ?? ''}?`}
    />
  );
};

export default DeletePostModal;
