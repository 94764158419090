export const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column-reverse',
    },
    mb: '16px'
  }
} as const;
