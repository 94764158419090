import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '@/context';

import type { MouseEvent } from 'react';

export const useMainLayout = () => {
  const navigate = useNavigate();
  const auth = useAuthContext();

  const user = auth.user;
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogoutClick = () => {
    auth.logout();
    navigate('/sign-in');
  };

  return {
    user,
    mobileOpen,
    anchorElUser,
    onLogoutClick,
    handleDrawerToggle,
    handleOpenUserMenu,
    handleCloseUserMenu,
  };
};
