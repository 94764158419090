import {
  Button,
  DialogActions,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import {
  ImageInput,
  SelectInput, SwitchInput,
  TextInput,
} from '@/components';

import {defaultInitialValues, HISTORY_POINTS_TYPES} from './constants';
import { styles } from './styles';

const HistoryPointForm: React.FC<any> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  return (
    <Formik<any>
      enableReinitialize
      //todo
      // validationSchema={postFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty, values, errors, handleChange }) => {
        return (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="date"
                  name="date"
                  component={TextInput}
                  placeholder="Date"
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="type"
                  placeholder="Type"
                  component={SelectInput}
                  size="small"
                >
                  {HISTORY_POINTS_TYPES.map((category) => (
                    <MenuItem value={category} key={category}>
                      <Typography>{category}</Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="title"
                  autoComplete="title"
                  fullWidth
                  component={TextInput}
                  placeholder="Title"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="description"
                  autoComplete="description"
                  fullWidth
                  component={TextInput}
                  placeholder="Description"
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs>
              <Field
                type="file"
                name="image"
                component={ImageInput}
                placeholder="Image"
              />
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="imageAlt"
                  autoComplete="imageAlt"
                  component={TextInput}
                  placeholder="Image Alt"
                />
              </Grid>
              <Grid item xs>
                  <SwitchInput
                    placeholder="isReverse"
                    onChange={handleChange}
                    name="isReverse"
                    checked={values?.['isReverse']}
                  />
              </Grid>
              <Grid item xs>
                <SwitchInput
                  placeholder="isRightSide"
                  onChange={handleChange}
                  name="isRightSide"
                  checked={values?.['isRightSide']}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !dirty}
                sx={styles.btn}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default HistoryPointForm;
