const palette = {
  mode: 'light',
  primary: {
    '50': '#F0F7FF',
    '100': '#C2E0FF',
    '200': '#A5D8FF',
    '300': '#66B2FF',
    '400': '#3399FF',
    '500': '#007FFF',
    '600': '#0072E5',
    '700': '#0059B2',
    '800': '#004C99',
    '900': '#003A75',
    main: '#005ccb',
    light: '#66B2FF',
    dark: '#233044',
    contrastText: '#fff',
  },
  divider: '#E5E8EC',
  primaryDark: {
    '50': '#E2EDF8',
    '100': '#CEE0F3',
    '200': '#91B9E3',
    '300': '#5090D3',
    '400': '#265D97',
    '500': '#1E4976',
    '600': '#173A5E',
    '700': '#132F4C',
    '800': '#001E3C',
    '900': '#0A1929',
    main: '#5090D3',
  },
  common: {
    black: '#111111',
    white: '#fff',
    red: '#FC474A',
    green: '#6fcf97',
    yellow: '#FEBD5B',
    blue: '#0e0080',
  },
  text: {
    primary: '#20262D',
    secondary: '#2F3A45',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  grey: {
    '50': '#FCFCFC',
    '100': '#EAEEF3',
    '200': '#E5E8EC',
    '300': '#D7DCE1',
    '400': '#BFC7CF',
    '500': '#A5B4CB',
    '600': '#7C8DA6',
    '700': '#46505A',
    '800': '#2F3A45',
    '900': '#222222',
    A100: '#f5f5f5',
    A200: '#eeeeee',
    A400: '#bdbdbd',
    A700: '#9e9e9e',
  },
  lightGrey: {
    main: '#F5F7F9',
    dark: '#EAEEF3',
    contrastText: '#7C8DA6',
  },
  error: {
    '50': '#FFF0F1',
    '100': '#FFDBDE',
    '200': '#FFBDC2',
    '300': '#FF99A2',
    '400': '#FF7A86',
    '500': '#FF505F',
    '600': '#EB0014',
    '700': '#C70011',
    '800': '#94000D',
    '900': '#570007',
    main: '#EB0014',
    light: '#FF99A2',
    dark: '#C70011',
    contrastText: '#fff',
  },
  success: {
    '50': '#E9FBF0',
    '100': '#C6F6D9',
    '200': '#9AEFBC',
    '300': '#6AE79C',
    '400': '#3EE07F',
    '500': '#21CC66',
    '600': '#1DB45A',
    '700': '#1AA251',
    '800': '#178D46',
    '900': '#0F5C2E',
    main: '#6FCF97',
    light: '#6AE79C',
    dark: '#6AE79C',
    contrastText: '#fff',
  },
  warning: {
    '50': '#FFF9EB',
    '100': '#FFF4DB',
    '200': '#FFEDC2',
    '300': '#FFE4A3',
    '400': '#FFD980',
    '500': '#FCC419',
    '600': '#FAB005',
    '700': '#F1A204',
    '800': '#DB9A00',
    '900': '#8F6400',
    main: '#F1A204',
    light: '#FFE4A3',
    dark: '#F1A204',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    main: '#ebeef2',
    light: '#ba68c8',
    dark: '#7b1fa2',
    contrastText: '#fff',
  },
  info: {
    main: '#0288d1',
    light: '#03a9f4',
    dark: '#01579b',
    contrastText: '#fff',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  background: {
    paper: '#fff',
    default: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};

export default palette;
