import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import {
  CustomTable,
  DeleteVacancyModal,
  VacancyTableItem,
} from '@/components';

import { filteringFields, headCells } from './constants';
import { useVacanciesPage } from './useVacanciesPage';

const VacanciesPage: React.FC = () => {
  const { vacanciesData, goToCreatePostPage, ...tableProps } =
    useVacanciesPage();

  return (
    <>
      <Button variant="contained" onClick={goToCreatePostPage}>
        <Add />
        <Typography ml={2} fontWeight="bold">
          Create Position
        </Typography>
      </Button>

      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeleteVacancyModal}
        {...tableProps}
      >
        {vacanciesData?.items.map((vacancy) => (
          <VacancyTableItem {...vacancy} key={vacancy.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default VacanciesPage;
