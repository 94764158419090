import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteVacancyMutation } from '@/apis/vacancies';
import { useToastMessageRequest } from '@/hooks';
import { vacanciesSelectors } from '@/redux/vacancies';
import { closeDeleteVacancyModal } from '@/redux/vacancies/slice';

export const useDeleteVacancyModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    vacanciesSelectors.isDeleteVacancyModalOpenSelector
  );
  const vacancy = useSelector(
    vacanciesSelectors.selectedVacancyForModalSelector
  );

  const [deleteVacancy, { isSuccess, ...requestParams }] =
    useDeleteVacancyMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteVacancyModal());
  };

  const confirmDeleting = () => {
    deleteVacancy(vacancy?.id ?? '');
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, vacancy, closeModal, confirmDeleting };
};
