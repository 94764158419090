import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { IFeedbackResponse } from '@/types/responses/feedback';
import { envUtil } from '@/utils';

import type { IRequestParams, IResponse } from '@/types';

const { api } = envUtil.getEnv();

export const feedbacksApi = createApi({
  reducerPath: 'feedbacksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Feedbacks', 'Feedback'],
  endpoints: (builder) => ({
    getFeedbacks: builder.query<IResponse<IFeedbackResponse>, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.Feedbacks,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Feedbacks' as const,
                id,
              })),
              { type: 'Feedbacks', id: 'LIST' },
            ]
          : [{ type: 'Feedbacks', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    getFeedbackById: builder.query<IFeedbackResponse, string>({
      query: (id) => ({
        url: `${ApiRoutes.Feedbacks}/${id}`,
      }),
      providesTags: () => ['Feedback'],
    }),
    editFeedback: builder.mutation<IFeedbackResponse, { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.Feedbacks}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Feedback'],
    }),
    getAttachmentFile: builder.mutation<string, string>({
      query: (fileName: string) => ({
        url: `${ApiRoutes.Feedbacks}/files/${fileName}`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob();

          return blob ? URL.createObjectURL(blob) : '';
        },
      }),
    }),
  }),
});

export const {
  useGetFeedbacksQuery,
  useGetFeedbackByIdQuery,
  useEditFeedbackMutation,
  useGetAttachmentFileMutation,
} = feedbacksApi;
