import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  authApi,
  usersApi,
  websiteSettingsApi,
  imagesApi,
  feedbacksApi,
  logsApi,
  postsApi,
  vacanciesApi,
  historyApi,
} from '@/apis';
import { metadataApi } from '@/apis/metadata';
import { feedbacksReducer } from '@/redux/feedbacks';
import { historyReducer } from '@/redux/historyPoints';
import { logsReducer } from '@/redux/logs';
import { metadataReducer } from '@/redux/metadatas';
import { postsReducer } from '@/redux/posts';
import { usersReducer } from '@/redux/users';

import { vacanciesReducer } from './vacancies';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [websiteSettingsApi.reducerPath]: websiteSettingsApi.reducer,
  [imagesApi.reducerPath]: imagesApi.reducer,
  [feedbacksApi.reducerPath]: feedbacksApi.reducer,
  [postsApi.reducerPath]: postsApi.reducer,
  [vacanciesApi.reducerPath]: vacanciesApi.reducer,
  [historyApi.reducerPath]: historyApi.reducer,
  [logsApi.reducerPath]: logsApi.reducer,
  [metadataApi.reducerPath]: metadataApi.reducer,
  users: usersReducer,
  logs: logsReducer,
  feedbacks: feedbacksReducer,
  posts: postsReducer,
  history: historyReducer,
  metadatas: metadataReducer,
  vacancies: vacanciesReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      usersApi.middleware,
      websiteSettingsApi.middleware,
      imagesApi.middleware,
      feedbacksApi.middleware,
      postsApi.middleware,
      historyApi.middleware,
      logsApi.middleware,
      metadataApi.middleware,
      vacanciesApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
