import { createSelector } from '@reduxjs/toolkit';

import type { IFeedbacksState } from './types';
import type { RootState } from '@/redux/store';

export const feedbacksStateSelector = (state: RootState) => state.feedbacks;

export const selectedFeedbackForModalSelector = createSelector(
  feedbacksStateSelector,
  (state: IFeedbacksState) => state.selectedFeedbackForModal
);

export const isDeleteFeedbackModalOpenSelector = createSelector(
  feedbacksStateSelector,
  (state: IFeedbacksState) => state.isDeleteFeedbackModalOpen
);
