import React from 'react';

import { DeleteConfirmationDialog } from '@/components';
import { useDeleteAllLogsModal } from '@/components/modals/DeleteAllLogsModal/useDeleteAllLogsModal';

export const DeleteAllLogsModal: React.FC = () => {
  const { isOpen, closeModal, confirmDeleting } = useDeleteAllLogsModal();

  return (
    <DeleteConfirmationDialog
      open={isOpen}
      onClose={closeModal}
      onConfirm={confirmDeleting}
      text={`Are you sure you want to delete all logs?`}
    />
  );
};
