import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateMetadataMutation } from '@/apis/metadata';
import { IMetadataValues } from '@/components/forms/MetadataForm/types';
import { Pathes } from '@/constants';
import { useToastMessageRequest } from '@/hooks';

export const useCreateMetadataPage = () => {
  const navigate = useNavigate();
  const [
    createMetadata,
    { isLoading, isSuccess, ...createMetadataRequestParams },
  ] = useCreateMetadataMutation();

  useToastMessageRequest({ isSuccess, ...createMetadataRequestParams });

  const onSubmit = (params: IMetadataValues) => {
    createMetadata(params);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Metadata);
    }
  }, [isSuccess]);

  return {
    isLoading,
    onSubmit,
  };
};
