import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {useEditHistoryPointMutation, useGetHistoryPointByIdQuery} from "@/apis/history";
import { useToastMessageRequest } from '@/hooks';

export const useEditHistoryPointPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: history } = useGetHistoryPointByIdQuery(id);
  const [editHistoryPoint, { isLoading: isEditHistoryPointLoading, ...editHistoryPointRequestParams }] =
    useEditHistoryPointMutation();

  useToastMessageRequest(editHistoryPointRequestParams);
  const initialHistoryPointValues = useMemo(
    () => ({
      reqId: history?.reqId ?? '',
      date: history?.date ?? '',
      type: history?.type ?? '',
      title: history?.title ?? '',
      description: history?.description ?? '',
      image: history?.image ?? '',
      imageAlt: history?.imageAlt ?? '',
      isReverse: history?.isReverse ?? '',
      isRightSide: history?.isRightSide ?? '',
    }),
    [history]
  );

  //todo
  const onEditHistorySubmit = useCallback(
    ({ ...params }: any) => {
      editHistoryPoint({
        id,
        ...params,
      });
    },
    [id]
  );

  return {
    initialHistoryPointValues,
    isEditHistoryPointLoading,
    onEditHistorySubmit,
  };
};
