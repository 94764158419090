import { Button, DialogActions, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { metadataFormSchema } from './schema';
import { styles } from './styles';
import { IMetadataValues } from './types';

interface MetadataFormProps {
  isLoading: boolean;
  onSubmit: (values: IMetadataValues) => void;
  initialValues?: IMetadataValues;
}

export const MetadataForm: React.FC<MetadataFormProps> = ({
  isLoading,
  onSubmit,
  initialValues = defaultInitialValues,
}) => {
  return (
    <Formik<IMetadataValues>
      enableReinitialize
      validationSchema={metadataFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => {
        return (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="url"
                  autoComplete="url"
                  fullWidth
                  component={TextInput}
                  placeholder="Url"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="title"
                  autoComplete="title"
                  fullWidth
                  component={TextInput}
                  placeholder="Title"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="h1"
                  autoComplete="h1"
                  fullWidth
                  component={TextInput}
                  placeholder="H1"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="description"
                  autoComplete="description"
                  fullWidth
                  component={TextInput}
                  placeholder="Description"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="keywords"
                  component={TextInput}
                  placeholder="Keywords"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="urlPrefix"
                  autoComplete="urlPrefix"
                  fullWidth
                  component={TextInput}
                  placeholder="Url prefix"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="imageUrl"
                  name="imageUrl"
                  autoComplete="imageUrl"
                  fullWidth
                  component={TextInput}
                  placeholder="Image url"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="subtitle"
                  autoComplete="subtitle"
                  fullWidth
                  component={TextInput}
                  placeholder="Subtitle"
                />
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !dirty}
                sx={styles.btn}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};
