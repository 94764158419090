import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';

export const headCells = [
  { title: 'Id', width: 100 },
  { title: 'Title', width: 280 },
  { title: 'Author', width: 180 },
  { title: 'Author position', width: 180 },
  { title: 'Image', width: 240 },
  { title: 'Created', width: 150 },
  { title: '', width: 100 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  { name: 'author', type: FilterTypes.Text },
  { name: 'author position', type: FilterTypes.Text },
  null,
  null,
  null,
];
