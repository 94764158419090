import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { FormControl, FormHelperText, Paper, Typography } from '@mui/material';
import React from 'react';

import { IRichTextEditorInputProps } from './types';
import { useRichTextEditorInput } from './useRichTextEditorInput';

const RichTextEditorInput = (props: IRichTextEditorInputProps) => {
  const { hasError, onChange } = useRichTextEditorInput(props);

  return (
    <FormControl error={hasError} style={{ width: '100%' }}>
      {props?.placeholder && (
        <Typography variant="h6" mb={1}>
          {props?.placeholder}
        </Typography>
      )}
      <Paper
        sx={{
          p: 2,
        }}
      >
        <CKEditor
          editor={ClassicEditor}
          data={props.field.value}
          onChange={onChange}
        />
      </Paper>
      {hasError && (
        <FormHelperText>{props.form.errors[props.field.name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RichTextEditorInput;
