import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEditPostMutation, useGetPostByIdQuery } from '@/apis/posts';
import { useToastMessageRequest } from '@/hooks';

export const useEditPostPage = () => {
  const params = useParams();
  const id = params.id as string;
  const { data: post } = useGetPostByIdQuery(id);
  const [editPost, { isLoading: isEditPostLoading, ...editPostRequestParams }] =
    useEditPostMutation();

  useToastMessageRequest(editPostRequestParams);

  const initialPostValues = useMemo(
    () => ({
      title: post?.title ?? '',
      description: post?.description ?? '',
      content: post?.content ?? [],
      quote: post?.quote ?? '',
      metaTitle: post?.metaTitle ?? '',
      metaH1: post?.metaH1 ?? '',
      metaDescription: post?.metaDescription ?? '',
      metaKeywords: post?.metaKeywords ?? '',
      metaSubTitle: post?.metaSubTitle ?? '',
      slug: post?.slug ?? '',
      author: post?.author ?? '',
      authorPosition: post?.authorPosition ?? '',
      reqId: post?.reqId ?? '',
      image: post?.image ?? null,
      lang: post?.lang ?? 'en',
      category: post?.category ?? '',
      noIndex: post?.noIndex ?? false,
    }),
    [post]
  );

  const onPostSubmit = useCallback(
    ({ ...params }: any) => {
      editPost({
        id,
        ...params,
      });
    },
    [id]
  );

  return {
    initialPostValues,
    isEditPostLoading,
    onPostSubmit,
  };
};
