export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  adornment: {
    mr: 1,
  },
  btn: {
    minWidth: 120,
  },
  containerSpacing: {
    lg: 3,
    md: 1,
    xs: 0,
  },
} as const;
