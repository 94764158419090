import { Container, Typography } from '@mui/material';
import React from 'react';

import { UserForm } from '@/components';
import { userEditFormSchema } from '@/components/forms/UserForm/schemas';

import { useEditUserPage } from './useEditUserPage';

const EditUserPage: React.FC = () => {
  const { initialUserValues, isEditUserLoading, onUserSubmit } =
    useEditUserPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        User form
      </Typography>
      <UserForm
        validationSchema={userEditFormSchema}
        initialValues={initialUserValues}
        isLoading={isEditUserLoading}
        onSubmit={onUserSubmit}
      />
    </Container>
  );
};

export default EditUserPage;
