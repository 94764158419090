import CloseIcon from '@mui/icons-material/Close';
import {Box, Grid, IconButton, Paper, Typography} from "@mui/material";
// eslint-disable-next-line import/named
import {Field, FieldArrayRenderProps} from 'formik';
import React from 'react';

import {ImageInput, RichTextEditorInput, DropdownButton, TextInput} from "@/components";
import {styles} from "@/components/forms/PostForm/styles";
import {theme} from "@/constants";
import {PostContentBlockTypes} from "@/types";

type PostContentEditorProps = {
    arrayHelpers: FieldArrayRenderProps;
    content: {
        type: string;
        data: any;
    }[];
}

const BLOCKS_DEFAULT_VALUES = {
    [PostContentBlockTypes.HTML]: {
        type: PostContentBlockTypes.HTML,
        data: {
            content: '',
        }
    },
    [PostContentBlockTypes.Image]: {
        type: PostContentBlockTypes.Image,
        data: {
            image: undefined,
            description: '',
        }
    },
    [PostContentBlockTypes.Quote]: {
        type: PostContentBlockTypes.Quote,
        data: {
            author: '',
            authorPosition: '',
            image: undefined,
            quoteText: ''
        }
    },
    [PostContentBlockTypes.Link]: {
        type: PostContentBlockTypes.Link,
        data: {
            title: '',
            link: '',
            description: ''
        }
    },
    [PostContentBlockTypes.Bordered]: {
        type: PostContentBlockTypes.Bordered,
        data: {
            content: ''
        }
    },
};

const BLOCK_TITLES = {
    [PostContentBlockTypes.HTML]: 'Text Block',
    [PostContentBlockTypes.Image]: 'Image Block',
    [PostContentBlockTypes.Quote]: 'Quote Block',
    [PostContentBlockTypes.Link]: 'Link Block',
    [PostContentBlockTypes.Bordered]: 'Bordered Block',
};

const PostContentEditor: React.FC<PostContentEditorProps> = ({ arrayHelpers, content }) => {
    const dropdownButtonOptions = [{
            id: 1,
            name: 'Text Block',
            onClick: () => arrayHelpers.push(BLOCKS_DEFAULT_VALUES[PostContentBlockTypes.HTML])
        },
        {
            id: 2,
            name: 'Image Block',
            onClick: () => arrayHelpers.push(BLOCKS_DEFAULT_VALUES[PostContentBlockTypes.Image])
        },
        {
            id: 3,
            name: 'Quote Block',
            onClick: () => arrayHelpers.push(BLOCKS_DEFAULT_VALUES[PostContentBlockTypes.Quote])
        },
        {
            id: 4,
            name: 'Link Block',
            onClick: () => arrayHelpers.push(BLOCKS_DEFAULT_VALUES[PostContentBlockTypes.Link])
        },
        {
            id: 5,
            name: 'Bordered Block',
            onClick: () => arrayHelpers.push(BLOCKS_DEFAULT_VALUES[PostContentBlockTypes.Bordered])
        }
    ];

    return (
    <Box sx={{ padding: 1, mb: 2 }}>
        <Box>
            {content.map((block, index) => (
                <Paper key={`${block.type}-${index}`} sx={{
                    padding: 1,
                    mb: 3
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, padding: '0 8px' }}>
                        <Typography variant="h6" sx={{ color: theme.palette.grey.A700 }}>{BLOCK_TITLES[block.type as PostContentBlockTypes]}</Typography>
                        <IconButton size="small" sx={{ padding: '4px' }} onClick={() => arrayHelpers.remove(index)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box>
                        {block.type === PostContentBlockTypes.HTML && (
                            <Grid container spacing={2} sx={styles.container}>
                                <Grid item xs>
                                    <Field
                                        type="text"
                                        style={{ minHeight: 200 }}
                                        id={`content.${index}.data.content`}
                                        name={`content.${index}.data.content`}
                                        fullWidth
                                        component={RichTextEditorInput}
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {block.type === PostContentBlockTypes.Image && (
                            <>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.description`}
                                            autoComplete={`content.${index}.data.description`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Description"
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="file"
                                            name={`content.${index}.data.image`}
                                            component={ImageInput}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {block.type === PostContentBlockTypes.Quote && (
                            <>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.author`}
                                            autoComplete={`content.${index}.data.author`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Quote author"
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.authorPosition`}
                                            autoComplete={`content.${index}.data.authorPosition`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Quote author position"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.quoteText`}
                                            autoComplete={`content.${index}.data.quoteText`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Quote text"
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="file"
                                            name={`content.${index}.data.image`}
                                            component={ImageInput}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {block.type === PostContentBlockTypes.Link && (
                            <>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.title`}
                                            autoComplete={`content.${index}.data.title`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Title"
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.link`}
                                            autoComplete={`content.${index}.data.link`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Link"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.description`}
                                            autoComplete={`content.${index}.data.description`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Description"
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="file"
                                            name={`content.${index}.data.image`}
                                            component={ImageInput}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {block.type === PostContentBlockTypes.Bordered && (
                            <>
                                <Grid container spacing={2} sx={styles.container}>
                                    <Grid item xs>
                                        <Field
                                            type="text"
                                            name={`content.${index}.data.content`}
                                            autoComplete={`content.${index}.data.content`}
                                            fullWidth
                                            component={TextInput}
                                            placeholder="Content"
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                </Paper>
            ))}
        </Box>
        <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
            <DropdownButton options={dropdownButtonOptions} />
        </Box>
    </Box>
    )
};

export default PostContentEditor;
