import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IRequestParams } from '@/types';

const { api } = envUtil.getEnv();

export const historyApi = createApi({
  reducerPath: 'historyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['HistoryPoints'],
  //todo
  endpoints: (builder) => ({
    getHistoryPoint: builder.query<any, IRequestParams>({
      query: (params) => ({
        url: ApiRoutes.HistoryPoints,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.items.map(({ id }: any) => ({
              type: 'History' as const,
              id,
            })),
            { type: 'History', id: 'LIST' },
          ]
          : [{ type: 'History', id: 'LIST' }],
      keepUnusedDataFor: 1,
    }),
    //todo
    getHistoryPointById: builder.query<any, string>({
      query: (id) => ({
        url: `${ApiRoutes.HistoryPoints}/${id}`,
      }),
      providesTags: () => ['HistoryPoints'],
    }),
    //todo
    createHistoryPoint: builder.mutation<any, void>({
      query: (body) => ({
        url: `${ApiRoutes.HistoryPoints}`,
        body,
        method: 'POST',
      }),
    }),
    //todo
    editHistoryPoint: builder.mutation<any, { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${ApiRoutes.HistoryPoints}/${id}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['HistoryPoints'],
    }),
    //todo
    deleteHistoryPoint: builder.mutation<void, string>({
      query: (id) => ({
        url: `${ApiRoutes.HistoryPoints}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'HistoryPoints', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetHistoryPointQuery,
  useCreateHistoryPointMutation,
  useEditHistoryPointMutation,
  useGetHistoryPointByIdQuery,
  useDeleteHistoryPointMutation,
} = historyApi;
