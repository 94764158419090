import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteMetadataMutation } from '@/apis/metadata';
import { useToastMessageRequest } from '@/hooks';
import { metadatasSelectors } from '@/redux/metadatas';
import { closeDeleteMetadataModal } from '@/redux/metadatas/slice';

export const useDeleteMetadataModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    metadatasSelectors.isDeleteMetadataModalOpenSelector
  );
  const metadata = useSelector(
    metadatasSelectors.selectedMetadataForModalSelector
  );

  const [deleteMetadta, { isSuccess, ...requestParams }] =
    useDeleteMetadataMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const closeModal = () => {
    dispatch(closeDeleteMetadataModal());
  };

  const confirmDeleting = () => {
    deleteMetadta(metadata?.id ?? '');
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return { isOpen, metadata, closeModal, confirmDeleting };
};
