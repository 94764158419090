import { Container, Typography } from '@mui/material';
import React from 'react';

import {HistoryPointForm} from '@/components';

import { useEditHistoryPointPage } from './useEditHistoryPointPage';


const EditHistoryPointPage: React.FC = () => {
  const {  initialHistoryPointValues, isEditHistoryPointLoading, onEditHistorySubmit } =
    useEditHistoryPointPage();
  return (
    <Container>
      <Typography variant="h4" my={2}>
        History point #{initialHistoryPointValues.reqId}
      </Typography>
      <HistoryPointForm
        initialValues={initialHistoryPointValues}
        isLoading={isEditHistoryPointLoading}
        onSubmit={onEditHistorySubmit}
      />
    </Container>
  );
};

export default EditHistoryPointPage;
