import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {useCreateHistoryPointMutation} from "@/apis/history";
import { Pathes } from '@/constants';
import { useToastMessageRequest } from '@/hooks';

export const useCreatePostPage = () => {
  const [createHistoryPoint, { isLoading, isSuccess, ...requestParams }] =
    useCreateHistoryPointMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.HistoryPoints);
    }
  }, [isSuccess]);
  //todo
  const onSubmit = useCallback((params: any) => {
    createHistoryPoint(params);
  }, []);

  return { isLoading, onSubmit };
};
