import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';

export const headCells = [
  { title: 'Id', width: 100 },
  { title: 'Name', width: 180 },
  { title: 'Email', width: 240 },
  { title: 'File', width: 240 },
  { title: 'Type', width: 130 },
  { title: 'Status', width: 130 },
  { title: 'Created', width: 130 },
  { title: '', width: 100 },
];

export const filteringFields: FiltersType = [
  { name: 'id', type: FilterTypes.Text },
  { name: 'name', type: FilterTypes.Text },
  { name: 'email', type: FilterTypes.Text },
  null,
  {
    name: 'type',
    type: FilterTypes.Select,
    values: [
      { label: 'Position', value: 'position' },
      { label: 'Get-in-touch', value: 'get_in_touch' },
    ],
  },
  {
    name: 'status',
    type: FilterTypes.Select,
    values: [
      { label: 'New', value: 'new' },
      { label: 'In progress', value: 'in_progress' },
      { label: 'Done', value: 'done' },
    ],
  },
  null,
];
