import { addMethod, array, string } from 'yup';

declare module 'yup' {
  interface ArraySchema<T> {
    unique<C>(mapper: (a: C) => string, message?: string): ArraySchema<T>;
  }
}

addMethod(array, 'unique', function <
  T,
>(mapper = () => string, message = '${path} may not have duplicates') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this.test('unique', message, (list: T[]) => {
    return list.length === new Set(list.map(mapper)).size;
  });
});
