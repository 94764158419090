import { useNavigate } from 'react-router-dom';

import { useGetMetadatasQuery } from '@/apis/metadata';
import { SortingOrderEnum } from '@/enums/sorting/sortingOrder';
import { usePagination, useTableFilters } from '@/hooks';

import { filteringFields } from './constants';

export const useMainMetadataPage = () => {
  const navigate = useNavigate();
  const [filters, onFiltersChange, onSortingChange, sortBy, isOrderByAsc] =
    useTableFilters(filteringFields);
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const { data: metadatas, isLoading } = useGetMetadatasQuery({
    limit,
    page: page + 1,
    sortBy,
    ...(isOrderByAsc !== undefined && {
      order: isOrderByAsc ? SortingOrderEnum.ASC : SortingOrderEnum.DESC,
    }),
    ...filters,
  });

  const count = (metadatas?.meta?.totalPages ?? 1) * limit;

  const noData = !metadatas?.items.length;

  const goToCreateMetadataPage = () => {
    navigate('create');
  };

  return {
    metadatas,
    count,
    noData,
    isLoading,
    onFiltersChange,
    goToCreateMetadataPage,
    onSortingChange,
    sortBy,
    isOrderByAsc,
    ...paginationParams,
  };
};
