import { createTheme } from '@mui/material/styles';

import components from './components';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
export type Theme = typeof theme;

const baseTheme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 992,
      xl: 1440,
    },
  },
});

export const theme = createTheme(baseTheme, {
  components,
  palette,
  typography,
  shadows,
});
