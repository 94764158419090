import React from 'react';

import { HistoryPointForm } from '@/components';

import { useCreatePostPage } from './useCreatePostPage';

const CreateHistoryPointPage = () => {
  const props = useCreatePostPage();
  return <HistoryPointForm {...props} />;
};

export default CreateHistoryPointPage;
