import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { CustomTable, DeletePostModal, PostTableItem } from '@/components';

import { filteringFields, headCells } from './constants';
import { useMainPostPage } from './useMainPostPage';

const MainPostPage: React.FC = () => {
  const { posts, goToCreatePostPage, ...tableProps } = useMainPostPage();

  return (
    <>
      <Button variant="contained" onClick={goToCreatePostPage}>
        <Add />
        <Typography ml={2} fontWeight="bold">
          Create Post
        </Typography>
      </Button>

      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeletePostModal}
        {...tableProps}
      >
        {posts?.items.map((post) => (
          <PostTableItem {...post} key={post.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainPostPage;
