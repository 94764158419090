import { createSlice } from '@reduxjs/toolkit';

import type { IFeedbacksState } from '@/redux/feedbacks/types';

const initialState: IFeedbacksState = {
  isDeleteFeedbackModalOpen: false,
  selectedFeedbackForModal: null,
};

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    openDeleteFeedbackModal(state: IFeedbacksState, { payload: feedback }) {
      state.isDeleteFeedbackModalOpen = true;
      state.selectedFeedbackForModal = feedback;
    },
    closeDeleteFeedbackModal(state: IFeedbacksState) {
      state.isDeleteFeedbackModalOpen = false;
      state.selectedFeedbackForModal = null;
    },
  },
});

export const { openDeleteFeedbackModal, closeDeleteFeedbackModal } =
  feedbacksSlice.actions;

export default feedbacksSlice.reducer;
