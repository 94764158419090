import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { CustomTable } from '@/components';
import DeleteHistoryPointModal from "@/components/modals/DeleteHistoryPointModal/DeleteHistoryPointModal";
import {HistoryPointItem} from "@/components/table-items/HistoryPointTableItem";

import { filteringFields, headCells } from './constants';
import { useMainHistoryPointsPage } from './useMainHistoryPointsPage';


const MainHistoryPointPage: React.FC = () => {
  const {  historyPoints, goToCreateHistoryPointPage, ...tableProps } = useMainHistoryPointsPage();
  return (
    <>
      <Button variant="contained" onClick={goToCreateHistoryPointPage}>
        <Add />
        <Typography ml={2} fontWeight="bold">
          Create History Point
        </Typography>
      </Button>

      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        deletingModal={DeleteHistoryPointModal}
        {...tableProps}
      >
        {historyPoints?.items.map((hPoint: any) => (
          //todo
          <HistoryPointItem {...hPoint} key={hPoint.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainHistoryPointPage;
