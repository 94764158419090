import React from 'react';

import { CustomTable, FeedbackTableItem } from '@/components';

import { filteringFields, headCells } from './constants';
import { useMainFeedbackPage } from './useMainFeedbackPage';

const MainFeedbackPage: React.FC = () => {
  const { feedbacks, ...tableProps } = useMainFeedbackPage();
  return (
    <>
      <CustomTable
        headCells={headCells}
        filteringFields={filteringFields}
        {...tableProps}
      >
        {feedbacks?.items.map((feedback) => (
          <FeedbackTableItem {...feedback} key={feedback.id} />
        ))}
      </CustomTable>
    </>
  );
};

export default MainFeedbackPage;
