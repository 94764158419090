import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from '@/constants';
import { setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IAuthType, ISignInResponse } from '@/types';

const { api } = envUtil.getEnv();

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  endpoints: (build) => ({
    loginUser: build.mutation<ISignInResponse, IAuthType>({
      query: (credentials) => ({
        url: `${ApiRoutes.Auth}/login`,
        method: 'POST',
        body: credentials,
      }),
    }),
    checkAuth: build.query<void, void>({
      query: () => ({
        url: `admin/me`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLoginUserMutation, useCheckAuthQuery } = authApi;
