import { Edit } from '@mui/icons-material';
import { TableCell, TableRow, IconButton, colors } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTableItem } from '@/hooks';
import { openDeleteUserModal } from '@/redux/users/slice';

import type { IFeedbackResponse } from '@/types';

const FeedbackTableItem: React.FC<IFeedbackResponse> = (feedback) => {
  const { id, reqId, name, email, file, type, status, createdAt } = feedback;

  const { onEdit } = useTableItem(openDeleteUserModal);

  const createdDate = format(new Date(createdAt), 'dd.MM.yyyy HH:MM:SS');

  return (
    <TableRow>
      <TableCell component="th" scope="row" width={100}>
        <RouterLink
          to={`edit/${id}`}
          style={{ textDecoration: 'none', color: colors.blue[600] }}
        >
          {reqId}
        </RouterLink>
      </TableCell>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="left">{file}</TableCell>
      <TableCell align="left" width={100}>
        {type}
      </TableCell>
      <TableCell align="left" width={100}>
        {status}
      </TableCell>
      <TableCell align="left" width={150}>
        {createdDate}
      </TableCell>
      <TableCell>
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        {/* <IconButton onClick={onDelete(feedback)}>
          <Delete />
        </IconButton> */}
      </TableCell>
    </TableRow>
  );
};

export default FeedbackTableItem;
