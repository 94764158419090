const components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: 'white',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '0px 0px',
        border: '1px solid #e4eaf3',
      },
      head: {
        color: 'black',
        background: 'white',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 15,
        lineHeight: '100%',
        letterSpacing: '-0.02em',
        padding: 10,
      },
      body: {
        color: '#111111',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '100%',
        letterSpacing: '-0.02em',
        paddingLeft: 10,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      head: {
        background: '#f5f7f9',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        width: '100%',
        fontWeight: 500,
        borderRadius: 4,
        border: '1px solid rgba(165, 180, 203, 0.24)',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '0.3px solid #374C5E',
          borderRadius: 4,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderWidth: '0.1px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderWidth: '0.1px',
        },
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },
      input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },
      multiline: {
        height: 'auto',
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        minHeight: 24,
        paddingBottom: 6,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
      },
    },
  },
};

export default components;
