import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'throttle-debounce';

import { useGetLogsQuery } from '@/apis/logs';
import { usePagination } from '@/hooks';
import { openDeleteAllLogsModal } from '@/redux/logs/slice';

export const useMainLogsPage = () => {
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState<string>('');

    const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        debounce(300, () => {
            setSearchValue(e?.target.value);
        })();
    }, []);

    const paginationParams = usePagination();
    const { page, rowsPerPage: limit } = paginationParams;

    const { data: logs, isLoading } = useGetLogsQuery({ page: page + 1, limit, search: searchValue });

    const onOpenDeleteAllLogsModal = () => {
        dispatch(openDeleteAllLogsModal())
    }

    const count = (logs?.meta.totalPages ?? 1) * limit;

    const noData = !logs?.items.length;

    return {
        logs,
        isLoading,
        noData,
        count,
        onSearchChange,
        onOpenDeleteAllLogsModal,
        ...paginationParams,
    }
}
