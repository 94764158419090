import React from 'react';

import { PostForm } from '@/components';

import { useCreatePostPage } from './useCreatePostPage';

const CreatePostPage = () => {
  const props = useCreatePostPage();
  return <PostForm {...props} />;
};

export default CreatePostPage;
