import { createSlice } from '@reduxjs/toolkit';

import type { IPostsState } from './types';

const initialState: IPostsState = {
  isDeletePostModalOpen: false,
  selectedPostForModal: null,
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    openDeletePostModal(state: IPostsState, { payload: post }) {
      state.isDeletePostModalOpen = true;
      state.selectedPostForModal = post;
    },
    closeDeletePostModal(state: IPostsState) {
      state.isDeletePostModalOpen = false;
      state.selectedPostForModal = null;
    },
  },
});

export const { openDeletePostModal, closeDeletePostModal } = postsSlice.actions;

export default postsSlice.reducer;
