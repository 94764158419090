import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiRoutes } from '@/constants';
import { objToFormData, setAccessTokenToHeaders } from '@/helpers';
import { envUtil } from '@/utils';

import type { IUploadImageParams, IImagesResponse } from '@/types';

const { api } = envUtil.getEnv();

export const imagesApi = createApi({
  reducerPath: 'imagesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers) => setAccessTokenToHeaders(headers),
  }),
  tagTypes: ['Challenges'],
  endpoints: (builder) => ({
    uploadImage: builder.mutation<IImagesResponse, IUploadImageParams>({
      query: (body) => ({
        url: ApiRoutes.Images,
        body: objToFormData<IUploadImageParams>(body),
        method: 'POST',
      }),
    }),
  }),
});

export const { useUploadImageMutation } = imagesApi;
