import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateUserMutation } from '@/apis/users';
import { userCreateFormSchema } from '@/components/forms/UserForm/schemas';
import { Pathes } from '@/constants';
import { useToastMessageRequest } from '@/hooks';

import type { IUserFormParams } from '@/types';

export const useCreateUserPage = () => {
  const validationSchema = userCreateFormSchema;

  const [createUser, { isLoading, isSuccess, ...requestParams }] =
    useCreateUserMutation();

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Users);
    }
  }, [isSuccess]);

  const onSubmit = useCallback(({ ...params }: IUserFormParams) => {
    createUser({
      ...params,
    });
  }, []);

  return {
    isLoading,
    onSubmit,
    validationSchema,
  };
};
